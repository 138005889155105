// <!-- API -->
import { computed } from 'vue';
import { StepContent } from '~CSVUploader/hooks/workflow/useStepContent';

// <!-- COMPOSABLES -->
import { useLocationIndex } from '@/hooks/cache/useLocationIndex';
import { useMappingProfileIndex } from '@/hooks/cache/useMappingProfileIndex';

// <!-- TYPES -->
import { UploadFormConfig } from '~CSVUploader/hooks/form/useUploadForm';
import { UploadStore } from '../store/useUploadStore';
import { UploadRecord } from '@/store/types/uploader/state/UploadRecord';
import ActionLogger from '@/utils/ActionLogger';

/**
 * Get the content prompt.
 * @param {'loading' | 'suggesting' | 'done'} status
 */
const getContentPrompt = (status) => {
    switch (status) {
        case 'loading':
            return `Loading account mappings.`;
        case 'suggesting':
            return `Suggesting mappings for selected datasets.`;
        default:
            return `Assign mappings for each dataset.`;
    }
};

/**
 * Get the content status.
 * @param {Map<Number, import('@/models/mappings/MappingProfile').MappingProfileResource>} index Available account mapping profiles.
 * @param {Number} total Total number of records.
 * @param {Number} profiles Count of selected mapping profiles.
 * @param {Number} suggested Count of suggested mapping profiles.
 * @param {Number} created Count of created mapping profiles.
 * @param {Number} applied Count of applied mapping profiles.
 * @param {Number} ingesting Count of ingesting datasets.
 * @param {Number} ingested Count of ingested datasets.
 * @param {Boolean} isFetching Are mapping profiles being fetched?
 * @param {Boolean} isSuggesting Are mapping profiles being suggested?
 */
const getContentStatus = (
    index,
    total,
    profiles,
    suggested,
    created,
    applied,
    ingesting,
    ingested,
    isFetching,
    isSuggesting
) => {
    const status = new Set();

    // ==== FETCHING ====
    if (isFetching === true) {
        status.add(`Loading mappings...`);
    }
    if (!isFetching && index.size <= 0) {
        status.add('No account mappings available.');
    }
    if (!isFetching && profiles <= 0) {
        status.add('No mappings applied.');
    } else if (!isFetching) {
        status.add(`${profiles} out of ${total} mapping(s) applied.`);
    }

    // ==== SUGGESTIONS ====
    if (isSuggesting === true) {
        status.add(`Loading mapping suggestions...`);
    }
    if (!isSuggesting && suggested <= 0) {
        status.add('No suggested mappings.');
    } else if (!isSuggesting) {
        status.add(`Found ${suggested} suggested mapping(s).`);
    }

    // ==== REPORTING ====
    if (ingesting > 0) {
        // ==== INGESTING ====
        status.clear();
        status.add(`Ingesting ${ingesting} dataset(s).`);
    }

    if (ingested > 0) {
        // ==== INGESTED ====
        status.add(`Finished ingesting ${ingested} dataset(s).`);
    }

    if (applied > 0) {
        // ==== APPLIED ====
        status.add(`Applied ${applied} mapping profiles to datasets.`);
    } else if (created > 0) {
        // ==== CREATED ====
        status.add(`Created ${created} mapping profiles.`);
    }

    // ==== EXPOSE ====
    return [...status].join(' ');
};

/**
 * Handle reactivity for the profile selector step.
 */
export const useProfileSelect = () => {
    /**
     * Create a dynamic computed content object.
     * @param {UploadStore} store
     * @param {Object} props
     * @param {V.Ref<Number>} props.mountKey Dependency tracking variable.
     * @param {V.Ref<Boolean>} props.isFetching Fetching locations?
     * @param {V.Ref<Boolean>} props.isSuggesting Suggesting locations?
     * @returns {V.ComputedRef<StepContent>}
     */
    const useContent = (store, { mountKey, isFetching, isSuggesting }) => {
        const $header = `Mapping Profile Select`;
        return computed(() => {
            // ts-ignore
            const _ = mountKey.value;
            const index = store.api.state.cache.profiles.index;
            const records = store.api.state.uploader.data.records;
            const whereSelected = [...records.values()].filter(
                (r) => r.isMappingProfileSelected || r.isMarkedAsPEM
            );
            const whereCreated = [...records.values()].filter(
                (r) => r.isMappingProfileCreated || r.isMarkedAsPEM
            );
            const whereApplied = [...records.values()].filter(
                (r) => r.isMappingProfileApplied
            );
            const whereSuggested = [...records.values()].filter(
                (r) => r.isMappingProfileSuggested
            );
            const whereIngesting = [...records.values()].filter(
                (r) => r.isDatasetBatchIngesting
            );
            const whereIngested = [...records.values()].filter(
                (r) => r.isDatasetBatchIngested
            );
            return StepContent.create()
                .setHeader($header)
                .setPrompt(
                    getContentPrompt(
                        isSuggesting.value
                            ? 'suggesting'
                            : isFetching.value
                            ? 'loading'
                            : 'done'
                    )
                )
                .setFooter(
                    getContentStatus(
                        index,
                        records.size,
                        whereSelected.length,
                        whereSuggested.length,
                        whereCreated.length,
                        whereApplied.length,
                        whereIngesting.length,
                        whereIngested.length,
                        isFetching.value,
                        isSuggesting.value
                    )
                );
        });
    };

    /**
     * Filter actions from the form.
     * @param {V.SetupContext<any, any>} context
     * @param {UploadFormConfig<any, any>} form
     */
    const useActions = (context, form) => {
        const $actions = form.data.useDataAction(context);
        return {
            /**
             * Input the specified mapping profile edits (or deselect it).
             * @param {{ record: UploadRecord, profile: import('@/models/mappings/MappingProfile').MappingProfileResource | null }} event
             */
            inputProfile: async (event) => {
                // Get event props.
                const { record, profile } = event;
                const logger = ActionLogger.log(`[select::profile]`);

                // If no record identified, nothing to do.
                if (!record || !record.filename) {
                    logger.failure(
                        `No record supplied. Nothing to select or drop.`
                    );
                    return;
                }

                // Attempt selection or drop.
                try {
                    // Get the filename.
                    const { filename = null } = record ?? {};

                    if (!profile || profile?.id === -1) {
                        // If profile payload is null, attempt drop.
                        if (!record.mappingProfile.exists) {
                            logger.warning(
                                `No mapping profile is currently selected. Nothing to drop.`
                            );
                            return;
                        } else {
                            logger.info(
                                `Attempting to drop mapping profile [${record.mappingProfile.value?.id}] from record "${filename}".`
                            );
                            const affected = await $actions.drop.location(
                                filename
                            );
                            logger.success(`Mapping Profile dropped!`);
                            return affected;
                        }
                    }

                    // If profile payload is non-null, attempt selection.
                    const affected = await $actions.input.profile(
                        filename,
                        profile
                    );
                    logger.success(`Mapping Profile selected!`);
                    return affected;
                } catch (err) {
                    logger.failure(err.message);
                    return null;
                }
            },
        };
    };

    // EXPOSE
    return {
        useContent,
        useActions,
        useLocationIndex,
        useMappingProfileIndex,
    };
};

export default useProfileSelect;
