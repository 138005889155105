<template>
    <LoadingWrapper
        :isLoading="!isReady"
        class="h-full w-full"
    >
        <div class="flex flex-nowrap flex-row-reverse items-center h-full">
            <label
                v-if="false && !!rendererLabel"
                :for="rendererId"
                >{{ rendererLabel }}</label
            >
            <div class="flex-grow h-full items-center max-h-8 mr-1">
                <VariantButton
                    :key="`action-${action.id}`"
                    variant="gridAction"
                    :overrides="['max-h-8', 'disabled:cursor-not-allowed']"
                    :alt="action.alt"
                    :disabled="!isReady"
                    @click.prevent="onButtonClick"
                >
                    <Component
                        :is="getActionIcon(action.icon)"
                        class="h-5 w-5 mr-1"
                    />
                    {{ action.label }}
                </VariantButton>
            </div>
            <div
                class="inline-flex flex-shrink-0 items-center"
                :id="`${rendererId}-status`"
            >
                <component
                    :is="getStatusIcon()"
                    class="h-6 w-6 mr-1"
                    :class="getStatusColor()"
                />
            </div>
        </div>
    </LoadingWrapper>
</template>

<script>
    // <!-- API -->
    import { defineComponent, toRefs, ref, computed } from 'vue';

    // <!-- UTILITIES -->
    import debounce from 'just-debounce-it';
    import isNil from 'lodash-es/isNil';

    // <!-- COMPONENTS -->
    import LoadingWrapper from '@/components/LoadingWrapper.vue';
    import VariantButton from '@/components/buttons/VariantButton.vue';
    import {
        StarIcon,
        CheckCircleIcon,
        XCircleIcon,
        EyeIcon,
        PencilAltIcon,
        SearchIcon,
    } from '@heroicons/vue/solid';

    // <!-- TYPES -->
    /** @typedef {import('@/models/locations/Location').LocationResource} LocationResource */
    /** @typedef {import('@/models/mappings/MappingProfile').MappingProfileResource} MappingProfileResource */

    /**
     * @typedef {Object} SelectProfileAPI
     * @property {(target: String) => void} show View the selected record's profile.
     * @property {(target: String) => void} edit Edit the selected record's profile.
     */

    // TODO: NEED TO SEND THIS TO THE MODAL, TO CONTROL DROP/SELECT/CREATE FUNCTIONALITY.
    //  * @property {(event: { filename: String }) => Promise<Awaited<UploadRecord>>} drop Drop record's transient mapping profile.
    //  * @property {(event: { filename: String, profile: Pick<MappingProfileResource, 'id'> }) => Promise<Awaited<UploadRecord>>} select Select record's transient mapping profile using an existing profile.
    //  * @property {(event: { filename: String, definition: Omit<MappingProfileResource, 'id'|'accountId'|'dateCreated'|'createdBy'> & Partial<MappingProfileResource> }) => Promise<Awaited<UploadRecord>>} create Select record's transient mapping profile using a newly provisioned profile.

    /**
     * @typedef {Object} ISelectProfileParams
     * @property {MappingProfileResource} value Actual value being passed to this cell.
     * @property {{ index: Number, name: String, type: String, size: Number, location: LocationResource, profile: MappingProfileResource, suggestion?: MappingProfileResource, viewing?: Boolean, editing?: Boolean, creating?: Boolean, created?: Boolean, applying?: Boolean, applied?: Boolean, ingesting?: Boolean, ingested?: Boolean, isPEMFile?: Boolean }} data Row data for this cell.
     * @property {String} label Renderer label.
     * @property {Map<Number, MappingProfileResource>} profileIndex Readonly profile index for all cells.
     */

    /**
     * @typedef {ISelectProfileParams & SelectProfileAPI & Omit<AgGrid.ICellRendererParams, 'value' | 'data'>} SelectProfileParams
     */

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'SelectProfileCellRenderer',
        components: {
            StarIcon,
            CheckCircleIcon,
            XCircleIcon,
            EyeIcon,
            PencilAltIcon,
            SearchIcon,
            VariantButton,
            LoadingWrapper,
        },
        props: {
            params: {
                /** @type {V.PropType<SelectProfileParams>} */
                // ts-ignore
                type: Object,
                required: true,
            },
        },
        setup(props, context) {
            // ==== PROPS ====

            /** @type {{ params: V.Ref<SelectProfileParams> }} */
            const { params } = toRefs(props);

            // ==== COMPOSABLES ====

            // Modify these configuration settings here in order to change behavior for all location cells.
            const useConfig = () => {
                const $node = params.value.node;
                const $data = params.value.data;
                return Object.freeze({
                    /** @type {Readonly<String>} Unique identifier for this renderer. */
                    rendererId: `select-profile-${$node?.id}`,
                    /** @type {Readonly<String>} Initial `<select>` element label value. */
                    rendererLabel: params.value?.label,
                    /** @type {Readonly<String>} Filename associated with this cell. The record id. */
                    filename: $data?.name,
                    /** @type {Readonly<Number>} Selected profile id. Set to `-1` when using a locally defined profile without an id. */
                    selected: isNil($data?.profile) ? null : $data?.profile.id,
                    /** @type {Readonly<MappingProfileResource>} Selected profile provided by the record. Local records will have an id of `-1`. */
                    profile: $data?.profile,
                    /** @type {Readonly<MappingProfileResource>} Mapping Profile suggested by the server. `null` if no suggestion has been made. */
                    suggestion: $data?.suggestion ?? null,
                    /** @type {Readonly<Boolean>} Is the profile being viewed? */
                    viewing: $data?.viewing,
                    /** @type {Readonly<Boolean>} Is the profile being edited? */
                    editing: $data?.editing,
                    /** @type {Readonly<Boolean>} Is the profile condition active? */
                    creating: $data?.creating,
                    /** @type {Readonly<Boolean>} Is the profile condition active? */
                    created: $data?.created,
                    /** @type {Readonly<Boolean>} Is the profile condition active? */
                    applying: $data?.applying,
                    /** @type {Readonly<Boolean>} Is the profile condition active? */
                    applied: $data?.applied,
                    /** @type {Readonly<Boolean>} Is the record ingesting? */
                    ingesting: $data?.ingesting,
                    /** @type {Readonly<Boolean>} Is the record ingested? */
                    ingested: $data?.ingested,
                    /** @type {Readonly<Boolean>} Is the record a PEM file? */
                    isPEMFile: $data?.isPEMFile,
                });
            };

            // Use this internal composable to generate the initial state for the cell renderer.
            const useState = () => ({
                /** @type {V.Ref<String>} Current renderer id. */
                rendererId: ref(config.rendererId),
                /** @type {V.Ref<String>} Current `<select>` element label. */
                rendererLabel: ref(config.rendererLabel),
                /** @type {V.Ref<Number>} Current selected profile id. Uses `-1` if local. Uses `null` if no profile has been selected. */
                selected: ref(null),
                /** @type {V.Ref<MappingProfileResource>} Current selected profile. Local profiles have `-1` ids. Placeholders will have a `null` id. */
                profile: ref(null),
                /** @type {V.Ref<Boolean>} Is the profile being viewed? */
                viewing: ref(config.viewing),
                /** @type {V.Ref<Boolean>} Is the profile being edited? */
                editing: ref(config.editing),
                /** @type {V.Ref<Boolean>} When true, cell is considered ready to display. */
                initialized: ref(false),
            });

            // Use this internal composable to generate computed properties for the cell renderer.
            const useComputed = () => {
                /** @type {V.ComputedRef<Boolean>} Is the cell ready to display options? */
                const isReady = computed(
                    () => state.initialized.value === true
                );

                /** @type {V.ComputedRef<Boolean>} */
                const isViewing = computed(
                    () => config.isPEMFile || config.viewing
                );

                /** @type {V.ComputedRef<Boolean>} */
                const isEditing = computed(
                    () => !config.isPEMFile && config.editing
                );

                /** @type {V.ComputedRef<Boolean>} */
                const isPEMFile = computed(() => config.isPEMFile === true);

                /** @type {V.ComputedRef<Boolean>} */
                const isCreating = computed(() => config.creating);

                /** @type {V.ComputedRef<Boolean>} */
                const isCreated = computed(() => config.created);

                /** @type {V.ComputedRef<Boolean>} */
                const isApplying = computed(() => config.applying);

                /** @type {V.ComputedRef<Boolean>} */
                const isApplied = computed(() => config.applied);

                /** @type {V.ComputedRef<Boolean>} */
                const isIngesting = computed(() => config.ingesting);

                /** @type {V.ComputedRef<Boolean>} */
                const isIngested = computed(() => config.ingested);

                /** @type {V.ComputedRef<{ alt: String, label: String, icon: 'pencil' | 'eye' | 'search'}>} */
                const action = computed(() => {
                    // If frozen, only allow view (indicated with different icon).
                    if (
                        isCreating.value ||
                        isCreated.value ||
                        isApplying.value ||
                        isApplied.value ||
                        isIngesting.value ||
                        isIngested.value
                    ) {
                        return {
                            alt: 'View',
                            label: 'View Mapping',
                            icon: 'search',
                        };
                    }

                    // If a PEM file, only allow viewing.
                    // If not frozen allow either edit or view.
                    const profile = state.profile.value;
                    const requireOverride =
                        !isPEMFile.value &&
                        (isNil(profile) ||
                            !profile?.name ||
                            !profile?.settings ||
                            profile?.rules.some((r) => isNil(r.index)));
                    const alt = requireOverride ? 'Create' : 'View';
                    const label = requireOverride
                        ? 'Edit Mapping'
                        : 'View Mapping';
                    const icon = requireOverride ? 'pencil' : 'eye';
                    return {
                        alt,
                        label,
                        icon,
                    };
                });

                // EXPOSE
                return {
                    isReady,
                    action,
                    isViewing,
                    isEditing,
                    isCreating,
                    isCreated,
                    isApplying,
                    isApplied,
                    isIngesting,
                    isIngested,
                    isPEMFile,
                };
            };

            // Use this internal composable to generate getters.
            const useGetters = () => {
                // ===== ID ====
                /**
                 * Is this id a placeholder id or missing from the profile index?
                 * @param {Number} id
                 */
                const isMissingID = (id) => {
                    return isNil(id) || !params.value.profileIndex.has(id);
                };

                /**
                 * Is this an existing profile id?
                 * @param {Number} id
                 */
                const isExistingID = (id) => {
                    return !isMissingID(id);
                };

                /**
                 * Is this the suggested profile id?
                 * @param {Number} id
                 */
                const isSuggestedID = (id) => {
                    return isExistingID(id) && config.suggestion?.id === id;
                };

                /**
                 * Is this a special provisional id?
                 * @param {Number} id
                 */
                const isLocalID = (id) => {
                    return !isNil(id) && id === -1;
                };

                // ===== PROFILE ====
                /**
                 * Is this a placeholder profile?
                 * @param {MappingProfileResource} profile
                 */
                const isPlaceholder = (profile) => {
                    return isNil(profile);
                };

                /**
                 * Is this an existing profile?
                 * @param {MappingProfileResource} profile
                 */
                const isExistingProfile = (profile) => {
                    return !isNil(profile) && isExistingID(profile?.id);
                };

                /**
                 * Is this a suggested profile?
                 * @param {MappingProfileResource} profile
                 */
                const isSuggestedProfile = (profile) => {
                    return (
                        isExistingProfile(profile) && isSuggestedID(profile?.id)
                    );
                };

                /**
                 * Is this a local profile?
                 * @param {MappingProfileResource} profile
                 */
                const isLocalProfile = (profile) => {
                    return !isNil(profile) && isLocalID(profile?.id);
                };

                // ===== SELECTED ====

                /**
                 * Is the provided profile selected?
                 * @param {MappingProfileResource} profile
                 */
                const isProfileSelected = (profile) => {
                    const selected = state.selected.value;
                    if (isMissingID(selected)) {
                        return isPlaceholder(profile);
                    }
                    if (isLocalID(selected)) {
                        return isLocalProfile(profile);
                    }
                    if (isExistingID(selected)) {
                        return isExistingProfile(profile);
                    }
                    return false;
                };

                /**
                 * Is the provided profile selected and the suggested profile?
                 */
                const isSuggestedSelected = () => {
                    const selected = state.selected.value;
                    return isSuggestedID(selected);
                };

                /** Get the status color. */
                const getStatusColor = () => {
                    const {
                        isPEMFile,
                        isCreating,
                        isCreated,
                        isApplying,
                        isApplied,
                        isIngesting,
                        isIngested,
                    } = properties;
                    const selected = state.selected.value;
                    const profile = state.profile.value;
                    if (!isPEMFile.value && isPlaceholder(profile)) {
                        // ===== INVALID =====
                        // Status is invalid when:
                        // - record is NOT a PEM file, AND
                        //   - No selected profile in state.selected.value, OR
                        //   - selected id is equivalent to -1.
                        return 'text-red-500';
                    }

                    // ===== INGESTED =====
                    // Status is ingested when:
                    // - ingesting or ingested is true.
                    if (isIngesting.value || isIngested.value) {
                        return 'text-primary-500';
                    }

                    // ===== VERIFIED =====
                    // Status is verified when:
                    // - creating, created, applying or applied is true.
                    if (
                        isCreating.value ||
                        isCreated.value ||
                        isApplying.value ||
                        isApplied.value
                    ) {
                        return 'text-primary-500 animate-pulse';
                    }

                    // ===== SUGGESTED =====
                    // Status is valid and suggested when:
                    // - A suggested profile exists.
                    // - selected has a non-null id.
                    // - selected id matches suggested id.
                    if (
                        isSuggestedID(selected) &&
                        isSuggestedProfile(profile)
                    ) {
                        return `text-secondary-500`;
                    }

                    // ===== VALID =====
                    // Status is valid when:
                    // - selected points to a PEM file.
                    if (isPEMFile.value === true) {
                        return `text-green-500`;
                    }

                    // ===== VALID =====
                    // Status is valid when:
                    // - selected points to an existing profile in the index, OR
                    // - selected is not placeholder.
                    if (isExistingID(selected) && isExistingProfile(profile)) {
                        return `text-green-500`;
                    }

                    // ===== VALID =====
                    // Status is valid when:
                    // - selected is -1, with non-null settings, non-null rules, and non-placeholder id.
                    if (isLocalID(selected) && isLocalProfile(profile)) {
                        return `text-green-400`;
                    }

                    // If the selected profile is the placeholder profile, return danger.
                    return 'text-red-500';
                };

                /** Get the status icon. */
                const getStatusIcon = () => {
                    const {
                        isPEMFile,
                        isCreating,
                        isCreated,
                        isApplying,
                        isApplied,
                        isIngesting,
                        isIngested,
                    } = properties;
                    const selected = state.selected.value;
                    const profile = state.profile.value;
                    if (!isPEMFile.value && isPlaceholder(profile)) {
                        // ===== INVALID =====
                        // Status is invalid when:
                        // - selected is not a PEM file, AND
                        //   - No selected profile in state.selected.value, OR
                        //   - selected id is equivalent to null.
                        return XCircleIcon;
                    }

                    // ===== INGESTED =====
                    // Status is ingested when:
                    // - ingesting or ingested is true.
                    if (isIngesting.value || isIngested.value) {
                        return CheckCircleIcon;
                    }

                    // ===== VERIFIED =====
                    // Status is verified when:
                    // - creating, created, applying or applied is true.
                    if (
                        isCreating.value ||
                        isCreated.value ||
                        isApplying.value ||
                        isApplied.value
                    ) {
                        return CheckCircleIcon;
                    }

                    // ===== SUGGESTED =====
                    // Status is valid and suggested when:
                    // - A suggested profile exists.
                    // - selected has a non-null id.
                    // - selected id matches suggested id.
                    if (
                        isSuggestedID(selected) &&
                        isSuggestedProfile(profile)
                    ) {
                        return StarIcon;
                    }

                    // ===== VALID =====
                    // Status is valid when:
                    // - selected is a PEM file.
                    if (isPEMFile.value === true) {
                        return CheckCircleIcon;
                    }

                    // ===== VALID =====
                    // Status is valid when:
                    // - selected points to an existing profile in the index, OR
                    // - selected is not placeholder.
                    if (isExistingID(selected) && isExistingProfile(profile)) {
                        return CheckCircleIcon;
                    }

                    // ===== VALID =====
                    // Status is valid when:
                    // - selected is -1, with non-null settings, non-null rules, and non-placeholder id.
                    if (isLocalID(selected) && isLocalProfile(profile)) {
                        return CheckCircleIcon;
                    }

                    // If the selected profile is the placeholder profile, return danger.
                    return XCircleIcon;
                };

                /**
                 * Get the action button icon
                 * @param {'eye' | 'pencil' | 'search'} type
                 */
                const getActionIcon = (type) => {
                    switch (type) {
                        case `eye`:
                            return EyeIcon;
                        case `pencil`:
                            return PencilAltIcon;
                        case `search`:
                            return SearchIcon;
                    }
                };

                // EXPOSE
                return {
                    isProfileSelected,
                    isSuggestedSelected,
                    isPlaceholder,
                    isSuggestedProfile,
                    isExistingProfile,
                    isLocalProfile,
                    isMissingID,
                    isSuggestedID,
                    isExistingID,
                    isLocalID,
                    getStatusColor,
                    getStatusIcon,
                    getActionIcon,
                };
            };

            // Use this internal composable to generate actions.
            const useActions = () => {
                /**
                 * Button click handler.
                 */
                const onButtonClick = async () => {
                    const { filename } = config;
                    const { icon } = properties.action.value;
                    switch (icon) {
                        case 'pencil':
                            debounce(params.value.edit, 50, true)(filename);
                            return;
                        default:
                        case 'eye':
                        case 'search':
                            debounce(params.value.show, 50, true)(filename);
                            return;
                    }
                };

                /**
                 * Determine the initial selection id.
                 * @param {Number} [input]
                 * @param {Number} [suggested]
                 * @param {Number | null} [placeholder]
                 */
                const useInitialSelection = (
                    input = null,
                    suggested = null,
                    placeholder = null
                ) => {
                    if (input === -1 || params.value.profileIndex.has(input)) {
                        // If valid existing id or new id, use it.
                        return input;
                    }

                    if (
                        !isNil(suggested) &&
                        suggested !== -1 &&
                        params.value.profileIndex.has(suggested)
                    ) {
                        // If no valid input, fallback to suggested id, if valid.
                        return suggested;
                    }

                    // If no valid input or suggested, use the placeholder.
                    return placeholder;
                };

                /**
                 * Event handler for when the component is initialized.
                 */
                const onInitialized = async () => {
                    // Prepare the deserialized input location id.
                    const input = {
                        id: config.selected ?? config?.profile?.id ?? null,
                    };

                    // Prepare the suggested location id.
                    const suggested = {
                        id: !isNil(config.suggestion)
                            ? config.suggestion?.id
                            : null,
                    };

                    // Prepare initial selected option.
                    const initial = {
                        selected: useInitialSelection(input.id, suggested.id),
                    };

                    if (isNil(initial.selected)) {
                        // If placeholder is being used, everything should be empty.
                        state.selected.value = null;
                        state.profile.value = null;
                    }

                    if (initial.selected === -1) {
                        // If local profile, update the initial selection accordingly.
                        state.selected.value = -1;
                        state.profile.value = Object.assign({}, config.profile);
                    }

                    if (
                        initial.selected !== -1 &&
                        params.value.profileIndex.has(initial.selected)
                    ) {
                        // If existing profile, update the initial selection accordingly.
                        state.selected.value = initial.selected;
                        const $profile = params.value.profileIndex.get(
                            state.selected.value
                        );
                        state.profile.value = Object.assign({}, $profile);
                    }

                    // Make initialized.
                    state.initialized.value = true;
                };

                // EXPOSE
                return {
                    onButtonClick,
                    onInitialized,
                };
            };

            // ==== SETTINGS ====

            // Load configuration.
            const config = useConfig();

            // ==== STATE ====

            // Assign state.
            const state = useState();

            // ==== COMPUTED PROPERTIES ====

            // Assign properties.
            const properties = useComputed();

            // ==== GETTERS ====

            // Assign getters.
            const getters = useGetters();

            // ==== ACTIONS ====

            // Assign actions.
            const actions = useActions();

            // ==== LIFECYCLE ====

            // Prepare the select options.
            actions.onInitialized();

            return {
                ...state,
                ...properties,
                ...getters,
                ...actions,
            };
        },
    });
</script>
