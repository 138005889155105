<template>
    <Panel class="mt-4">
        <template #header>
            <div class="flex flex-row">
                <h3
                    class="inline-flex flex-grow align-middle items-center float-left text-xl text-primary-700 col-span-3"
                >
                    {{ content.prompt }}
                </h3>
            </div>
        </template>
        <template #default>
            <BasicModal
                v-if="selector.status.isPEMFile.value !== true"
                size="lg"
                :show="selector.state.open.value"
                @close="selector.methods.closeModal"
            >
                <SelectProfileModal
                    :form="form"
                    :selector="selector"
                />
            </BasicModal>
            <BasicModal
                v-else
                size="lg"
                :show="selector.state.open.value"
                @close="selector.methods.closeModal"
            >
                <PreviewPEMModal :selector="selector" />
            </BasicModal>
            <div>
                <LoadingWrapper :isLoading="isLoading">
                    <div v-if="isProfileIndexEmpty">
                        No account mapping profiles are currently available.
                    </div>
                    <div v-if="!isProfileSelectionAllowed">
                        Please select files before creating mapping profiles.
                    </div>
                    <SelectProfileGrid
                        v-else
                        :records="records"
                        :profileIndex="profileIndex"
                        :mountKey="mountKey"
                        @view:profile="onViewProfile"
                        @edit:profile="onEditProfile"
                        @alert="$emit('alert', $event)"
                    />
                </LoadingWrapper>
                <DebugFrame
                    :key="getMountKey(`profile-select-debug`)"
                    id="generic"
                    :debug="frame.isEnabled"
                    :data="frame.data"
                />
            </div>
        </template>
        <template #footer>
            <p class="text-center">{{ content.footer }}</p>
        </template>
    </Panel>
</template>

<script>
    // <!-- API -->
    import {
        defineComponent,
        computed,
        toRefs,
        onMounted,
        onUnmounted,
    } from 'vue';

    // <!-- COMPONENTS -->
    import Panel from '@/components/Panel.vue';
    import LoadingWrapper from '@/components/LoadingWrapper.vue';
    import BasicModal from '@components/BasicModal.vue';
    import DebugFrame from '@/components/debug/DebugFrame.vue';
    import SelectProfileGrid from '~CSVUploader/components/grids/SelectProfileGrid.vue';

    // <!-- COMPOSABLES -->
    import {
        useDebugFrame,
        DebugObject,
    } from '@/hooks/reactivity/useDebugFrame';
    import { useMounted } from '@vueuse/core';
    import { useMountKey } from '@/hooks/reactivity/useMountKey';
    import { useProfileSelect } from '~CSVUploader/hooks/input/useProfileSelect';
    import { useProfileSuggestor } from '~CSVUploader/hooks/data/useProfileSuggestor';
    import { useSelectProfileForm } from '~CSVUploader/hooks/form/useSelectProfileForm';
    import { useStepModal } from '~CSVUploader/hooks/workflow/useStepComponent';

    // <!-- TYPES -->

    /** @typedef {import('@/models/mappings/MappingProfile').MappingProfileResource} MappingProfileResource */
    import { UploadFormConfig } from '~CSVUploader/hooks/form/useUploadForm';
    import { UploadRecord } from '@/store/types/uploader/state/UploadRecord';
    import { FormNavigationPolicy } from '@/hooks/useNavigationPolicies';
    import collect from 'collect.js';

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'ProfileSelectorStep',
        components: {
            Panel,
            BasicModal,
            SelectProfileGrid,
            LoadingWrapper,
            DebugFrame,
            // ASYNC COMPONENT(S)
            SelectProfileModal: useStepModal('SelectProfileModal'),
            PreviewPEMModal: useStepModal('PreviewPEMModal'),
        },
        props: {
            form: {
                /** @type {V.PropType<UploadFormConfig<any, any>>} */
                type: Object,
                required: true,
            },
        },
        emits: ['alert', 'set:policies'],
        setup(props, context) {
            // ==== PROPS ====

            const { form } = toRefs(props);
            const { store, cache } = form.value;
            const { useContent, useActions, useMappingProfileIndex } =
                useProfileSelect();

            // ==== COMPOSABLES ====

            /**
             * Get the component isMounted tracker
             * for use with the loading components.
             */
            const isMounted = useMounted();

            /**
             * Get the component mount key hack
             * used to force updates to the component state
             * when subscribed to certain actions and mutations.
             */
            const {
                mountKey,
                getNamespacedMountKey,
                subscribeWatchedActions,
                subscribeWatchedMutations,
                resetMountKey,
            } = useMountKey();

            // Check if profile editing is allowed.
            const { isProfileEditingAllowed } = form.value.methods;

            // Get the mapping profile index api.
            const {
                isFetching: isFetchingProfileIndex,
                profiles,
                refreshMappingProfileIndex,
            } = useMappingProfileIndex(cache);

            // Get the mapping profile suggestion api.
            const {
                isFetchingSuggestions,
                suggestions,
                refreshMappingProfileSuggestions,
            } = useProfileSuggestor(store.api.store);

            // Get the dynamic content.
            const content = useContent(store, {
                mountKey,
                isFetching: isFetchingProfileIndex,
                isSuggesting: isFetchingSuggestions,
            });

            // ==== COMPUTED PROPERTIES ====

            /**
             * Are mapping profiles being fetched?
             */
            const isFetchingProfiles = computed(() => {
                const _ = mountKey.value;
                return isFetchingProfileIndex.value === true;
            });

            /**
             * Are locations being suggested?
             */
            const isSuggestingProfiles = computed(() => {
                const _ = mountKey.value;
                const isActive =
                    store.api.state.uploader.data.isSuggestingMappingProfiles;
                return isFetchingSuggestions.value === true || isActive;
            });

            /**
             * Are datasets being uploaded?
             */
            const isUploading = computed(() => {
                const _ = mountKey.value;
                const records = store.api.state.uploader.data.records;
                const whereUploading = collect(
                    [...records].filter(([_, r]) => r.isUploadingDataset)
                );
                return whereUploading.isNotEmpty();
            });

            /**
             * Ingestion is not allowed if:
             * - Dataset is missing a profile selection.
             * - Datasets are being uploaded
             * - Profiles are being created
             * - Profiles are already created
             * - Profiles are being applied
             * - Profiles are already applied
             * - Datasets are being ingested
             * - Datasets are already ingested
             */
            const isIngestionAllowed = computed(() => {
                const _ = mountKey.value;
                const records = store.api.state.uploader.data.records;
                const whereDenied = collect(
                    [...records].filter(([_, r]) => {
                        return (
                            (!r.isMappingProfileSelected && !r.isMarkedAsPEM) ||
                            r.isUploadingDataset ||
                            r.isMappingProfileCreated ||
                            r.isMappingProfileApplied ||
                            r.isDatasetBatchIngested ||
                            isIngesting.value
                        );
                    })
                );
                return whereDenied.isEmpty();
            });

            /**
             * Are datasets being ingested?
             */
            const isIngesting = computed(() => {
                const _ = mountKey.value;
                const records = store.api.state.uploader.data.records;
                const whereIngesting = collect(
                    [...records].filter(([_, r]) => {
                        return (
                            r.isCreatingMappingProfile ||
                            r.isApplyingMappingProfile ||
                            r.isDatasetBatchIngesting
                        );
                    })
                );
                return whereIngesting.isNotEmpty();
            });

            /**
             * Are all datasets uploaded?
             */
            const isEveryRecordUploaded = computed(() => {
                const _ = mountKey.value;
                const records = store.api.state.uploader.data.records;
                const whereUploaded = collect(
                    [...records].filter(([_, r]) => r.isDatasetBatchUploaded)
                );
                return (
                    whereUploaded.isNotEmpty() &&
                    whereUploaded.count() === records.size
                );
            });

            /**
             * Are all datasets ingested?
             */
            const isEveryRecordIngested = computed(() => {
                const _ = mountKey.value;
                const records = store.api.state.uploader.data.records;
                const whereIngested = collect(
                    [...records].filter(([_, r]) => r.isDatasetBatchIngested)
                );
                return (
                    whereIngested.isNotEmpty() &&
                    whereIngested.count() === records.size
                );
            });

            /**
             * Prepare a loading wrapper conditional.
             */
            const isLoading = computed(() => {
                const _ = mountKey.value;
                const isNotReady = isMounted.value !== true;
                return (
                    isNotReady ||
                    isFetchingProfiles.value ||
                    isFetchingSuggestions.value ||
                    isUploading.value ||
                    isIngesting.value
                );
            });

            /**
             * Is the file selection set empty?
             */
            const isFileSelectionEmpty = computed(() => {
                const _ = mountKey.value;
                const records = store.api.state.uploader.data.records;
                const whereFileSelected = collect(
                    [...records].filter(([_, r]) => r.isFileSelected)
                );
                return whereFileSelected.isEmpty();
            });

            /**
             * Is the location index empty?
             */
            const isLocationIndexEmpty = computed(() => {
                const _ = mountKey.value;
                const cached = store.api.state.cache.locations;
                return cached.index.size <= 0;
            });

            /**
             * Is the profile index empty?
             */
            const isProfileIndexEmpty = computed(() => {
                const _ = mountKey.value;
                const cached = store.api.state.cache.profiles;
                return cached.index.size <= 0;
            });

            /**
             * Is the profile selector visible?
             */
            const isProfileSelectionAllowed = computed(() => {
                const _ = mountKey.value;
                return (
                    !isFetchingProfiles.value &&
                    !isSuggestingProfiles.value &&
                    !isFileSelectionEmpty.value
                );
            });

            /**
             * Is the profile selection set empty?
             */
            const isProfileSelectionEmpty = computed(() => {
                const _ = mountKey.value;
                const records = store.api.state.uploader.data.records;
                const whereSelected = collect(
                    [...records].filter(
                        ([_, r]) =>
                            r.isMappingProfileSelected || r.isMarkedAsPEM
                    )
                );
                return whereSelected.isEmpty();
            });

            /** @type {V.ComputedRef<Map<String, UploadRecord>>} */
            const records = computed(() => {
                const _ = mountKey.value;
                return store.api.state.uploader.data.records;
            });

            /** @type {V.ComputedRef<Map<Number, MappingProfileResource>>} */
            const profileIndex = computed(() => {
                const _dep1 = mountKey.value;
                const _dep2 = profiles.value;
                return store.api.state.cache.profiles.index;
            });

            /**
             * Profile selector.
             * @type {ReturnType<useSelectProfileForm>}
             */
            const selector = useSelectProfileForm(form.value);

            // ==== ACTIONS ====

            const { inputProfile } = useActions(context, form.value);

            const getRecord = (index) => {
                const array = [
                    ...form.value.store.api.state.uploader.data.records.values(),
                ];
                return index >= 0 && index < array.length ? array[index] : null;
            };

            /** @param {{ record: UploadRecord }} event */
            const onViewProfile = (event) => {
                const { record } = event;
                console.log(`Show modal in view mode for ${record.filename}.`);
                selector.methods.bindTarget().withRecord(record);
                selector.methods.startView();
                selector.methods.showModal();
            };

            /** @param {{ record: UploadRecord }} event */
            const onEditProfile = (event) => {
                const { record } = event;
                console.log(`Show modal in edit mode for ${record.filename}`);
                selector.methods.bindTarget().withRecord(record);
                selector.methods.startEdit();
                selector.methods.showModal();
            };

            // ==== NAVIGATION POLICIES ====

            const isSubmit = {
                visible: true,
                allowed: computed(() => {
                    const _ = mountKey.value;
                    const records = store.api.state.uploader.data.records;
                    const whereProfileMissing = collect(
                        [...records].filter(
                            ([_, r]) =>
                                !r.isMappingProfileSelected && !r.isMarkedAsPEM
                        )
                    );
                    // NOTE: Upload can be triggered when:
                    // - Every record has had a dataset batch uploaded (and ready for ingestion).
                    // - The form is not loading.
                    // - The form is not already ingesting.
                    // - There is at least one record.
                    // - For all records, one mapping profile is selected OR is a PEM file.
                    return (
                        isIngestionAllowed.value &&
                        isEveryRecordUploaded.value &&
                        !isFileSelectionEmpty.value &&
                        !isLoading.value &&
                        whereProfileMissing.isEmpty()
                    );
                }),
            };

            const submit = FormNavigationPolicy.prepare()
                .type.asSubmit()
                .label.exact('Next')
                .visible.exact(isSubmit.visible)
                .enabled.withComputed(isSubmit.allowed)
                .action.onClick(async () => {
                    console.dir(isSubmit);
                    if (isIngestionAllowed.value) {
                        return await store.createMappingProfiles();
                    }
                    if (isEveryRecordIngested.value) {
                        return await store.goToNextStep(true);
                    }
                })
                .create();

            const isReset = {
                hasIngested: computed(() => {
                    const _ = mountKey.value;
                    const records = store.api.state.uploader.data.records;
                    const whereIngested = [...records.values()].filter(
                        (r) => r.isDatasetBatchIngested
                    );
                    return records.size > 0 && whereIngested.length > 0;
                }),
                label: computed(() => {
                    return isReset.hasIngested.value === true
                        ? 'Cancel'
                        : 'Back';
                }),
                visible: true,
                allowed: computed(() => {
                    const _ = mountKey.value;
                    return !isIngesting.value;
                }),
            };

            const reset = FormNavigationPolicy.prepare()
                .type.asReset()
                .label.withComputed(isReset.label)
                .visible.exact(isReset.visible)
                .enabled.withComputed(isReset.allowed)
                .action.onClick(async () => {
                    console.dir(isReset);
                    if (isReset.hasIngested.value === true) {
                        form.value.handlers.onShowConfirmDeleteModal(
                            'New Upload?',
                            'This will discard your current upload summary.'
                        );
                    } else {
                        await store.goToPreviousStep(isReset.allowed.value);
                    }
                })
                .create();

            // ==== LIFECYCLE (onMounted) ====

            subscribeWatchedActions(store.api.store, [`*`]);
            subscribeWatchedMutations(store.api.store, [`*`]);

            onMounted(async () => {
                context.emit('set:policies', { submit, reset });
                await refreshMappingProfileIndex(false);
                await refreshMappingProfileSuggestions(records.value, false);
            });

            // ==== LIFECYCLE (onUnmounted) ====

            onUnmounted(() => {
                isMounted.value = false;
                resetMountKey();
            });

            // DEBUG
            /**
             * Computed debug frame.
             */
            const frame = computed(
                () => {
                    const _ = mountKey.value;
                    /** @type {Map<String, UploadRecord>} */
                    const records = store.api.state.uploader.data.records;
                    const profiles = store.api.state.cache.profiles;
                    const selectedRecords = [...records.values()].map((r) => {
                        const {
                            flags,
                            status,
                            file,
                            location,
                            suggestedLocation,
                            mappingProfile,
                            suggestedMappingProfile,
                            batch,
                        } = r ?? {};
                        const { name, size, type } = file.value ?? {};
                        return {
                            id: name,
                            file: {
                                name,
                                type,
                                size: `${size} bytes`,
                            },
                            flags: [...flags].join(','),
                            status: [...status].join(','),
                            location: {
                                transient: location?.transient,
                                persisted: location?.persisted,
                                suggested: suggestedLocation,
                            },
                            profile: {
                                transient: mappingProfile?.transient,
                                persisted: mappingProfile?.persisted,
                                suggested: suggestedMappingProfile,
                            },
                            batch,
                        };
                    });

                    const suggestedProfiles = [...records.values()]
                        .filter((r) => r.isMappingProfileSuggested)
                        .map((r) => r.suggestedMappingProfile);

                    const selectedProfiles = [...records.values()]
                        .filter(
                            (r) => r.isMappingProfileSelected || r.isMarkedAsPEM
                        )
                        .map((r) => r.mappingProfile.value);

                    const viewingProfiles = [...records.values()]
                        .filter((r) => r.isViewingMappingProfile)
                        .map((r) => r.mappingProfile.value);

                    const editingProfiles = [...records.values()]
                        .filter((r) => r.isEditingMappingProfile)
                        .map((r) => r.mappingProfile.value);

                    const creatingProfiles = [...records.values()]
                        .filter((r) => r.isCreatingMappingProfile)
                        .map((r) => r.mappingProfile.value);

                    const createdProfiles = [...records.values()]
                        .filter((r) => r.isMappingProfileCreated)
                        .map((r) => r.mappingProfile.value);

                    const applyingProfiles = [...records.values()]
                        .filter((r) => r.isApplyingMappingProfile)
                        .map((r) => r.mappingProfile.value);

                    const appliedProfiles = [...records.values()]
                        .filter((r) => r.isMappingProfileApplied)
                        .map((r) => r.mappingProfile.value);

                    // Create current step structure.
                    const { id, label, description } =
                        store.api.state.uploader.workflow.currentStep;
                    const currentStep = {
                        id,
                        label,
                        description,
                    };

                    // Prepare data.
                    const data = [
                        DebugObject.create('Current Step', currentStep),
                        DebugObject.create('Mapping Profiles', {
                            index: `${profiles.index.size} Mapping Profile(s)`,
                            found: `${suggestedProfiles.length} suggestion(s).`,
                            missing: `${
                                records.size - suggestedProfiles.length
                            } record(s) with no suggested mapping profile.`,
                            selected: `Selected ${selectedProfiles.length} profile(s) out of ${records.size} total record(s).`,
                            pending: `${
                                records.size - selectedProfiles.length
                            } record(s) with no selected mapping profile.`,
                            viewing: `Viewing ${viewingProfiles.length} Mapping Profile(s).`,
                            editing: `Editing ${editingProfiles.length} Mapping Profile(s).`,
                            creating: `Creating ${creatingProfiles.length} Mapping Profile(s).`,
                            created: `Created ${createdProfiles.length} Mapping Profile(s).`,
                            applying: `Applying ${applyingProfiles.length} Mapping Profile(s).`,
                            applied: `Applied ${appliedProfiles.length} Mapping Profile(s).`,
                        }),
                        ...selectedRecords.map((r) =>
                            DebugObject.create(`Record`, r)
                        ),
                    ];

                    // Return new frame instance.
                    return useDebugFrame({
                        startHidden: isLoading.value,
                        data,
                    });
                },
                {
                    // onTrack(e) {
                    //     debugger;
                    // },
                    // onTrigger(e) {
                    //     debugger;
                    // },
                }
            );

            /** Expose to the Options API. */
            return {
                // REACTIVITY
                isLoading,
                isMounted,
                mountKey,
                getMountKey: getNamespacedMountKey,

                // PROPS
                records,
                selector,
                suggestions,
                profileIndex,

                // DEBUG
                frame,

                // STEP CONTENT
                content,

                // STEP CONDITIONALS
                isFileSelectionEmpty,
                isProfileIndexEmpty,
                isProfileSelectionAllowed,

                // STEP ACTIONS
                inputProfile,
                onViewProfile,
                onEditProfile,
            };
        },
    });
</script>
